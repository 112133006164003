import Dashboard from "./Dashboard";
import Gegevens from "./Gegevens";
import Facturen from "./Facturen";
import OpenstaandeFacturen from "./OpenstaandeFacturen";
import Rapportages from "./Rapportages";
import Meerwerk from "./Meerwerk";
import DocumentView from "./DocumentView";
import Tickets from "./Tickets";
import Ticket from "./Ticket";
import Login from "./Login";
import NotFound from "./NotFound";
import JDBEvenement from "./JDBEvenement";

import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";


function Routes(props) {
  const data = props.data
  const history = props.history
  const notifications = props.notifications
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route path="/dashboard" component={() => <Dashboard data={data} history={history} notifications={notifications} />} />
      <Route path="/gegevens" component={() => <Gegevens data={data} history={history} />} />
      <Route path="/facturen" component={() => <Facturen data={data} history={history} />} />
      <Route path="/openstaandefacturen" component={() => <OpenstaandeFacturen data={data} history={history} />} />
      <Route path="/rapportages" component={() => <Rapportages data={data} history={history} notifications={notifications} />} />
      <Route path="/meerwerk" component={() => <Meerwerk data={data} history={history} notifications={notifications} />} />
      <Route path="/documenten" component={() => <DocumentView data={data} history={history} notifications={notifications} />} />
      <Route path="/tickets" component={() => <Tickets data={data} history={history} />} />
      <Route path="/support" component={() => <Ticket data={data} history={history} />} />
      <Route path="/evenement" component={() => <JDBEvenement data={data} history={history} />} />
      <Route component={() => <NotFound data={data} history={history} />} />
    </Switch>
  )
}

export default withRouter(Routes)