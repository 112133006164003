import React from "react";
import GenerateTable from '../Javascripts/Tools';

class Tickets extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    render() {
        const data = this.props.data
        const notifications = this.props.notifications

        if ( data.Tickets === 'Fetching' || data.ContactPersoon === undefined) {
            return (
                <div className="mainContent">
                    {GenerateTable(true, data.Tickets, "Tickets")}
                </div>
            )
        }
        else {
            return (
                <div className="mainContent">
                    {GenerateTable(false, data.Tickets, "Tickets", ["Onderwerp", "Status"], false, this.routingFunction, notifications, true)}
                </div>
            )
        }
    }
}

export default Tickets;