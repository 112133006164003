// import axios from 'axios';
import { PostData } from '../Javascripts/API';

export async function openFile(url, filename = null) {
    try {
      const response = await PostData(`${process.env.REACT_APP_API_URL}/file`, { url }, 'arraybuffer')

        const buffer = response;
        console.log({buffer})

        // Convert buffer to Blob in the browser environment
        const blob = new Blob([buffer], { type: 'application/pdf' });

        const fileURL = URL.createObjectURL(blob);

        if(/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream){
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = filename || "Download"
    
          link.dispatchEvent(
            new MouseEvent('click', { 
              bubbles: true, 
              cancelable: true, 
              view: window 
            })
          );
    
          setTimeout(() => {
            window.URL.revokeObjectURL(fileURL);
            link.remove();
          }, 100);
        }
        else{
          window.open(fileURL, '_blank')
        }
    } catch (error) {
        // Handle any errors that might occur during the download
        console.error('Error while downloading the PDF:', error.message);
        throw error;
    }
}