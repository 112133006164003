import '../Stylesheets/Navbar.css';
import ContactModal from './ContactModal';
import { Puff } from  'react-loader-spinner'
import { NavLink } from "react-router-dom";
import React from "react";
import Loading from './Loading';
import { GetData } from '../Javascripts/API';

class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
        this.uitloggen = this.uitloggen.bind(this);
        this.handleNotif = this.handleNotif.bind(this);
    }

    state = {
        navDisplay: 'navbar hideOnPhone',
        icon: 'fa fa-bars',
        loading: false,
        isSupportDropdownVisible: false,
        isFacturenDropdownVisible: false,
    }

    toggleNav = () => {
        if (this.state.navDisplay === 'navbar hideOnPhone') {
            this.setState({ navDisplay: 'navbar' });
            this.setState({ icon: 'fa fa-times' })
        }
        else {
            this.setState({ navDisplay: 'navbar hideOnPhone' });
            this.setState({ icon: 'fa fa-bars' })
        }
    }

    closeNav = () => {
        this.setState({ navDisplay: 'navbar hideOnPhone' });
        this.setState({ icon: 'fa fa-bars' })
        window.scrollTo(0, 0)
    }

    toggleSupportDropdown = () => {
        this.setState(prevState => ({
            isSupportDropdownVisible: !prevState.isSupportDropdownVisible,
        }));
    }
    toggleFacturenDropdown = () => {
        this.setState(prevState => ({
            isFacturenDropdownVisible: !prevState.isFacturenDropdownVisible,
        }));
    }

    uitloggen = () => {
        this.setState({loading: true})
        GetData(`${process.env.REACT_APP_API_URL}/logout`)
        .then(() => {
            window.location.href = "/"
        })
        .catch(() => {
            window.location.href = "/"
        })
    }

    handleNotif = (amount) => {
        if(amount === 0){
            return <div className="notificationBlank"></div>
        }
        else if (amount === undefined) {
            return (
                <div className='notificationLoading'>
                    <Puff
                        height = "26"
                        width = "26"
                        color = 'var(--cyan)'
                    />
                </div>
            )
        }
        else {
            return (
                <div className="notification">
                    <p>{amount}</p>
                </div>
            )
        }
    }

    render() {
        const data = this.props.data
        const notifications = this.props.notifications

        if(this.state.loading){
            return <Loading />
        }
        else{
            return (
                <div>
                    <div id="topBarPhone">
                        <div id="nameBox">
                            <p id="name">{data.ContactPersoon ? data.ContactPersoon.VolledigeNaam : ''}</p>
                            <p id="subName">{data.Organisatie ? data.Organisatie.Naam : ''}</p>
                        </div>
                        <img src="https://webserver.4proces.nl/imgupload/uploads/4Psignature_EXT.png" alt="4PROCES Logo"></img><i className={this.state.icon} aria-hidden="true" onClick={() => this.toggleNav()}></i>
                    </div>
                    <div className={this.state.navDisplay}>
                        <div className="navbarHeader">
                            {/* <div id="companyLogo">
                                <img src="https://webserver.4proces.nl/imgupload/uploads/4Psignature_EXT.png" alt="4PROCES Logo"></img>
                            </div> */}
                            <p>Welkom terug<b>{data.ContactPersoon ? `,\n${data.ContactPersoon.VolledigeNaam}` : ''}</b></p>
                        </div>
                        <hr />
                        <div className="navbarOptions">
                            <NavLink to="/dashboard" activeClassName="selected" onClick={() => this.closeNav()}>
                                <div className="iconSpacing">
                                    <i className="fa fa-chart-bar" aria-hidden="true"></i>
                                </div>
                                Dashboard
                                <div className="notificationBlank">
    
                                </div>
                            </NavLink>
                            <div className='navToggleSubmenu' onClick={this.toggleFacturenDropdown}>
                                <div className="iconSpacing">
                                    <i className="fa fa-calculator" aria-hidden="true"></i>
                                </div>
                                Facturen
                                <div className="dropdownChevron">
                                    <i className={this.state.isFacturenDropdownVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} aria-hidden="true"></i>
                                </div>
                            </div>

                            {this.state.isFacturenDropdownVisible && (
                                <div className="navDropdown">
                                    <NavLink to="/openstaandefacturen" activeClassName="selected" className={!data.ContactPersoon || !data.ContactPersoon.facturenInzien ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                        <div className="iconSpacing">
                                            <i className="fa fa-file-invoice-dollar" aria-hidden="true"></i>
                                        </div>
                                        Openstaande facturen
                                        {this.handleNotif(notifications.OpenstaandeFacturen)}
                                    </NavLink>
                                        <NavLink to="/facturen" activeClassName="selected" className={!data.ContactPersoon || !data.ContactPersoon.facturenInzien ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                        <div className="iconSpacing">
                                            <i className="fa fa-file-invoice" aria-hidden="true"></i>
                                        </div>
                                        Alle facturen
                                        <div className="notificationBlank">
            
                                        </div>
                                    </NavLink>
                                </div>
                            )}
                            <NavLink to="/rapportages" activeClassName="selected" className={!data.ContactPersoon || !data.ContactPersoon.rapportageInzien ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                <div className="iconSpacing">
                                    <i className="fa fa-file-alt" aria-hidden="true"></i>
                                </div>
                                Rapportages
                                {this.handleNotif(notifications.RapportagesOngelezen)}
                            </NavLink>
                            <NavLink to="/meerwerk" activeClassName="selected" className={!data.ContactPersoon || !data.ContactPersoon.meerwerkInzien ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                <div className="iconSpacing">
                                    <i className="fa fa-plus-square" aria-hidden="true"></i>
                                </div>
                                Meerwerk
                                {this.handleNotif(notifications.MeerwerkOngelezen)}
                            </NavLink>
                            <div className='navToggleSubmenu' onClick={this.toggleSupportDropdown}>
                                <div className="iconSpacing">
                                    <i className="fa fa-headset" aria-hidden="true"></i>
                                </div>
                                Support
                                <div className="dropdownChevron">
                                    <i className={this.state.isSupportDropdownVisible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'} aria-hidden="true"></i>
                                </div>
                            </div>

                            {this.state.isSupportDropdownVisible && (
                                <div className="navDropdown">
                                    <NavLink to="/tickets" activeClassName="selected" className={!data.ContactPersoon ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                        <div className="iconSpacing">
                                            <i className="fa fa-comments" aria-hidden="true"></i>
                                        </div>
                                        Mijn tickets
                                    </NavLink>
                                    <NavLink to="/support" activeClassName="selected" className={!data.ContactPersoon ? "optionDisabled" : ''} onClick={() => this.closeNav()}>
                                        <div className="iconSpacing">
                                            <i className="fa fa-comment" aria-hidden="true"></i>
                                        </div>
                                        Nieuw ticket
                                    </NavLink>
                                    <NavLink to={{pathname: "https://get.teamviewer.com/6sjy387"}} target="_blank">
                                        <div className="iconSpacing">
                                            <i className="fa fa-headset" aria-hidden="true"></i>
                                        </div>
                                        Hulp op afstand
                                    </NavLink>
                                    <ContactModal />
                                </div>
                            )}
                            {/* <a href='https://4proces.nl/nieuws/' target='#blank'>
                                <div className="iconSpacing">
                                    <i className="fa fa-newspaper" aria-hidden="true"></i>
                                </div>
                                Nieuws<i style={{marginLeft: '10px'}} className="fa fa-external-link-alt" aria-hidden="true"></i>
                                <div className="notificationBlank">
    
                                </div>
                            </a> */}
                        </div>
                        <hr />
                        <div className="navbarLowerOptions" onClick={() => this.closeNav()}>
                            <NavLink to="/gegevens" activeClassName="selected" id="gegevensTab">
                                <div className="iconSpacing">
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                </div>
                                Mijn account
                            </NavLink>
                            <p onClick={() => this.uitloggen()}>
                                <div className="iconSpacing">
                                    <i className="fa fa-arrow-left" aria-hidden="true"></i>
                                </div>
                                Uitloggen
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Navbar;
