import '../Stylesheets/Dashboard.css';
import '../Stylesheets/Global.css';
import React from 'react'

function NewsItemLoading(props) {

    const bericht = props.bericht;
    
    return (
        <div id='newsSection' className={bericht.Type}>
            <div className={`news-header ${bericht.Type}-head`}>
                {
                    bericht.Type === 'informatie' ?
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    : bericht.Type === 'waarschuwing' ?
                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    : bericht.Type === 'update' ?
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                    : bericht.Type === 'alert' ?
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    : null
                }
                <h1>{bericht.Titel}</h1>
            </div>
            <div className={`news-body`} style={{height: '88px', display: 'flex', alignItems: 'center'}}>
                <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo" style={{height: '30px', width: '30px', marginLeft: '12px'}}></img>
                <p>Bezig met laden...</p>
            </div>
        </div>
    )
}

export default NewsItemLoading