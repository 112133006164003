import '../Stylesheets/DragDropImage.css';
import React from 'react';

function DragDropImage({ handleChange, image, id }) {

  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleChange(e)
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      {image ?
        <div id="preview-container">
          <img id="image-preview" src={image.file.split('/')[0] === 'data:image' ? image.file : '../images/placeholder.png'} alt="Preview"/>
          <p id="file-name">{image.file.split('/')[0] !== 'data:image' ? image.name : null}</p>
          <button type="button" onClick={() => {handleChange({target: {id, value: ''}})}}><i className="fa fa-trash" aria-hidden="true"></i></button>
        </div>
        :
        <div id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input ref={inputRef} type="file" className='input-file-upload' id={`${id}#click`} multiple={false} onChange={(e) => handleChange(e)} />
          <label id="label-file-upload" htmlFor={`${id}#click`} className={dragActive ? "drag-active" : ""}>
            <div>
              <p>Sleep het bestand hier of</p>
              <button type="button" className="upload-button" onClick={onButtonClick}>Kies een bestand</button>
            </div>
          </label>
          {dragActive && <div id={`${id}#drop`} className="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </div>
      }
    </>
  );
};

export default DragDropImage;