import React from "react";
import GenerateTable from '../Javascripts/Tools';

class Facturen extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    render() {
        const data = this.props.data

        if ( data.Facturen === 'Fetching' || data.ContactPersoon === undefined) {
            return (
                <div className="mainContent">
                    {GenerateTable(true, data.Facturen, "Facturen")}
                </div>
            )
        }
        else {
            return (
                <div className="mainContent">
                    {GenerateTable(false, data.Facturen, "Facturen", ["FactuurNummer", "FactuurDatum", "Omschrijving", "TotaalInclBTW", "PDF"], false, this.routingFunction, null, data.ContactPersoon.facturenInzien)}
                </div>
            )
        }
    }
}

export default Facturen;