import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import '../Stylesheets/Modal.css';
import { PostData } from '../Javascripts/API';

class PasswordModal extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    var msg = event.target.value
    if(event.target.id === "oldPassword"){
      this.setState({ oldpsw: msg.replace(/\s/g, '')});
    } else if(event.target.id === "newPassword"){
      this.setState({ psw: msg.replace(/\s/g, '')});
      if (msg.trim().length >= 6) {
        this.setState({ color: 'green' });
        this.setState({ icon: 'fa fa-check' });
        this.setState({ btnDisabled: false });
      }
      else {
        this.setState({ color: 'red' });
        this.setState({ icon: 'fa fa-times' });
        this.setState({ btnDisabled: true });
      }
    } 
  }

  handleSubmit() {
    var psw = this.state.psw.trim();
    var oldpsw = this.state.oldpsw.trim();
    if (psw.length >= 6) {

      PostData(`${process.env.REACT_APP_API_URL}/gegevens/reset`, { oldPassword: oldpsw, newPassword: psw })

      this.closeModal();
    }
    else {
      alert("Uw wachtwoord moet uit minimaal 6 tekens bestaan")
    }
  }

  state = {
    openModal: false,
    psw: '',
    oldpsw: '',
    color: 'red',
    icon: 'fa fa-times',
    btnDisabled: true
  }

  openModal = () => this.setState({ openModal: true })
  closeModal = () => this.setState({ openModal: false })

  render() {

    const customStyles = {
      content: {
        position: 'relative',
        inset: 'auto',
        padding: 0,
        margin: '10% auto 0',
        width: '400px'
      },
    };

    return <div id="modalContainer">
      <p onClick={this.openModal} id="changePassword">Wachtwoord wijzigen</p>
      <Modal id="passwordModal" open={this.state.openModal} onClose={this.closeModal} style={customStyles}>
        <div className="modal-content">
          <span className="close" onClick={this.closeModal}>&times;</span>
          <h1>Wachtwoord wijzigen</h1>
          <hr id="modal_hr" />
          <div className="modal">
            <div id="modal-information" style={{ marginLeft: '18px' }}>
              <label htmlFor={"oldPassword"} style={{ marginTop: '10px' }}>Huidig wachtwoord</label><br />
              <input type="password" id="oldPassword" name="oldPassword" value={this.state.oldpsw} onChange={this.handleChange}/>
              <label htmlFor={"newPassword"}>Nieuw wachtwoord</label><br />
              <input type="password" id="newPassword" name="newPassword" value={this.state.psw} onChange={this.handleChange} tabIndex={0} />
            </div>
            <ul id="passwordMessage">
              <li id="first">
                <i className={this.state.icon} aria-hidden="true" style={{ 'color': this.state.color }}></i>Gebruik voor uw nieuwe wachtwoord <b>minimaal 6 tekens</b>.
              </li>
              <li>
                <div style={{ display: 'flex' }}>
                  <p id="passwordTip">Tip</p>
                  <p style={{ marginLeft: '10px' }}>Een sterk wachtwoord bestaat uit letters, cijfers en symbolen.</p>
                </div>
              </li>
            </ul>
          </div>
          <div id="centerPasswordButtons">
            <button id="cancelPassword" onClick={this.closeModal}>
              Annuleren
            </button>
            <button id="savePassword" onClick={this.handleSubmit} disabled={this.state.btnDisabled}>
              Opslaan
            </button>
          </div>
        </div>
      </Modal>
    </div>
  }
}

export default PasswordModal;