function FetchData(id, type = 'dashboard', router, notifications = null) {
    var data = {}
    GetData(`${process.env.REACT_APP_API_URL}/${type === "Openstaande facturen" ? "openstaandefacturen" : type}${id ? '/' + id : ''}`)
        .then(response => {
            console.log({response})
            data = response[0]
            switch (type) {
                case "Facturen":
                    data["Type"] = "Factuur"
                    break;
                case "Openstaande facturen":
                    data["Type"] = "Factuur"
                    break;
                case "Rapportages":
                    data["Type"] = "Rapportage"
                    break;
                case "Tickets":
                    data["Type"] = "Ticket"
                    break;
                default:
                    data["Type"] = type
            }

            if (data.Gelezen !== undefined && data.Gelezen !== "Ja") {
                if (type !== "Meerwerk") {
                    var updateNotif = notifications[`${type}Ongelezen`][1]
                    var count = notifications[`${type}Ongelezen`][0]
                    updateNotif(count - 1)
                }
                PostData(`${process.env.REACT_APP_API_URL}/${type.toLowerCase()}/${id}`, { "Gelezen": "Ja" })
            }

            console.log({data})

            router('documenten', data)
        })
        .catch(error => {
            console.error(error);
        })
}

async function GetData(url = '') {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://192.168.0.143:3010'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
    });
    if (response.status === 401 && window.location.pathname !== "/") {
        window.location.href = "/"
    }
    else {
        return response.json(); // parses JSON response into native JavaScript objects
    }
}

async function PostData(url = '', data = {}, responseType = 'json') {
    const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'http://192.168.0.143:3010'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if(responseType === 'arraybuffer'){
        return response.arrayBuffer()
    }
    else{
        return response.json();
    }
}

export {
    PostData,
    GetData,
    FetchData as default
}