// import '../Stylesheets/Gegevens.css';
import '../Stylesheets/JDB_Evenement.css';
import React from 'react';
import { PostData } from '../Javascripts/API';
import Loading from './Loading';

class JDB_Evenement extends React.Component {
  state = {
    loading: false
  }

  componentDidMount() {
    if(this.props.data.ContactPersoon?.ingeschreven === 1)
    this.props.history.push({
        pathname: '/dashboard',
        state: { data: this.props.data }
    });
    console.log({data: this.props})
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({loading: true})
    
    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));

    try {
      PostData(`${process.env.REACT_APP_API_URL}/jdb_evenement`, data)
        .then(data => {
            if (data.message !== 'Authenticated') {
                this.setState({loading: false})
            } else {
                window.location.href = ('/dashboard');
            }

        })
        .catch(data => {
            this.setState({loading: false})
        })
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  render() {
    const data = this.props.data

    if (data === undefined || data.ContactPersoon === undefined || data.Organisatie === undefined) {
      return <div className="mainContent">
        <div id="mainSection" style={{ paddingBottom: '40px' }}>
          <h1>Aanmelding Jeu de Boules Event 4PROCES 2024</h1>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo" style={{ height: '30px', width: '30px', marginLeft: '12px' }}></img>
            <p>Bezig met laden...</p>
          </div>
        </div>
      </div>
    }
    else if (!data.ContactPersoon.gegevensInzien) {
      return <div className="mainContent">
        <div id="mainSection">
          <h1>Aanmelding Jeu de Boules Event 4PROCES 2024</h1>
          <hr id="separator" />
          <p>U heeft geen rechten om deze gegevens te bekijken.
            <br />Is dit niet juist? Neem contact op met onze administratie.
            <br /><br /><a id="contactLink" href={"tel:0252794001"}><span style={{ marginRight: '5px' }}><i className="fa fa-phone" aria-hidden="true"></i></span>+31(0)252-794001</a></p>
        </div>
      </div>
    }
    else {
      return (
        <div className="mainContent">
          <div id="mainSection">
            <h1>Aanmelding Jeu de Boules Event 4PROCES 2024</h1>
            <div id="informationContainer">
              <div id="info-container-blue">
                <p>Leuk dat je meedoet aan het jaarlijkse 4PROCES Jeu de Boules event!</p>
                <p>Elk jaar strijden we om die felbegeerde Wisseltrofee.</p>
                <p>Jij bent uitgenodigd voor de wedstrijd op vrijdag 1 maart 2024. Strijd mee en maak kans op een plek in de finale op 6 september 2024.</p>
                <p><b>Let op:</b> Je mag een introducé meenemen, dus nodig gerust een collega, zakenpartner of vriend uit om samen deel te nemen aan deze middag.</p>
              </div>
              <div id="video-container">
                <div id="video-wrapper">
                  <iframe
                  src="https://www.youtube.com/embed/5SR32NJilr4"
                  title="Jeu de Boules Event 4PROCES 2024"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
                </div>
              </div>
            </div>
            {
              this.state.loading && (
                <Loading />
              )
            }
            {
              data.ContactPersoon.ingeschreven !== 1 && !this.state.loading && (
                <div id="aanmeldformulier">
                  <h2 style={{ padding: '14px 0' }}>Meld je hier aan!</h2>
                  <form id="evenement-form" onSubmit={this.handleSubmit}>
                    <label>
                      Naam: <span style={{ color: 'red' }}>*</span>
                      <input type="text" name="naam" required />
                    </label>
                    <label>
                      Bedrijfsnaam:
                      <input type="text" name="bedrijfsnaam" />
                    </label>
                    <label>
                      E-mailadres: <span style={{ color: 'red' }}>*</span>
                      <input type="email" name="emailadres" required />
                    </label>
                    <label>
                      Telefoonnummer: <span style={{ color: 'red' }}>*</span>
                      <input type="tel" name="telefoonnummer" required />
                    </label>
                    <label>
                      Aantal deelnemers: <span style={{ color: 'red' }}>*</span>
                      <input type="number" name="aantalDeelnemers" required />
                    </label>
                    <label>
                      Naam introducé:
                      <input type="text" name="naamIntroduce" />
                    </label>
                    <label>
                      E-mailadres introducé:
                      <input type="email" name="emailadresIntroduce" />
                    </label>
                    <label style={{ paddingBottom: '140px' }}>
                      Dieetwensen:
                      <textarea name="dieetwensen"></textarea>
                    </label>
                    <input type="submit" value="Aanmelden" />
                  </form>
                </div>
              ) 
            }
          </div>
        </div>
      );
    }
  }
}

export default JDB_Evenement;