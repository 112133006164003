import { v4 } from 'uuid';
import FetchData from '../Javascripts/API';
import { NavLink } from "react-router-dom";
import { openFile } from './OpenFile';

function GenerateTable(isLoading, array, title, headers, dashboardTable, router = null, notifications = null, rechten) {
    var content = null,
        titleh1 = null,
        link = null,
        slicedArray = [],
        unreadArray = [],
        table = {}
    if(isLoading){
        return <div id="mainSection">
                {createTitle()}
                {/* <hr id="separator" /> */}
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo" style={{height: '30px', width: '30px', marginLeft: '12px'}}></img>
                    <p>Bezig met laden...</p>
                </div>
            </div>
    }
    if(!rechten){
        return <div id="mainSection">
                {createTitle()}
                {/* <hr id="separator" /> */}
                <p>U heeft geen rechten om {title === "Meerwerk" ? "het meerwerk" : title === "Rapportages" ? "de rapportages" : title === "Facturen" ? "de facturen" : title === "Tickets" ? "de tickets" : "deze informatie"} te bekijken.
                <br />Is dit niet juist? Neem contact op met onze administratie.
                <br /><br /><a id="contactLink" href={"tel:0252794001"}><span style={{marginRight:'5px'}}><i className="fa fa-phone" aria-hidden="true"></i></span>+31(0)252-794001</a></p>
            </div>
    }
    if (array === undefined) {
        if (!dashboardTable) {
            return <div>
                <h1>{title}</h1>
                <p>Er zijn geen documenten gevonden.</p>
            </div>
        }
        else {
            titleh1 = createTitle()
            content = createContent()
            link = createLink()
            return assembleContent()
        }
    }
    else {
        var headerList = {
            'FactuurDatum':     'Datum',
            'TotaalInclBTW':    'Bedrag',
            'TotaalOpen':       'Openstaand',
            'TotaalBetaald':    'Betaald',
            'id':               'ID',
            'FactuurNummer':   'Factuurnummer',
            'Verlopen':           ''
        }

        if (array.length > 0) {
            if(title === "Rapportages"){
                array.forEach((element) => {
                    if (element.Gepubliceerd.toLowerCase() !== "ja" || element.PDF === "") {
                        array.splice(array.indexOf(element),1)
                    }
                })
            }
            if (dashboardTable) {
                if(title === "Facturen") {
                    slicedArray = array.slice(0, 5)
                }
                else if(title === "Meerwerk"){
                    array.forEach((element) => {
                        if (element.Akkoord === "") {
                            unreadArray.push(element)
                        }
                    })
                    slicedArray = unreadArray.slice(0, 3)
                }
                else if(title === "Tickets") {
                    array.forEach((element) => {
                        console.log({element})
                        if (element.Status !== "Gesloten") {
                            unreadArray.push(element)
                        }
                    });
                    slicedArray = unreadArray.slice(0, 3)
                }
                else {
                    array.forEach((element) => {
                        if (element.Gelezen?.toLowerCase() !== "ja") {
                            unreadArray.push(element)
                        }
                    })
                    slicedArray = unreadArray.slice(0, 3)
                }
            }
            else if(title === "Meerwerk") {
                array.forEach((element) => {
                    if (element.Akkoord === "") {
                        slicedArray.push(element)
                    }
                });
                array.forEach((element) => {
                    if (element.Akkoord !== "") {
                        slicedArray.push(element)
                    }
                });
            }
            else {
                array.forEach((element) => {
                    if (element.Gelezen !== "Ja") {
                        slicedArray.push(element)
                    }
                });
                array.forEach((element) => {
                    if (element.Gelezen === "Ja") {
                        slicedArray.push(element)
                    }
                });
            }
            if (slicedArray.length > 0) {
                content =
                    <table className={title}>
                        <thead>
                            <tr>
                                {headers.map((key) => {
                                    return <th className={"table_" + (key in headerList ? headerList[key] : key)} key={key}>{key !== "PDF" ? key in headerList ? headerList[key] : key : ''}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {slicedArray.map(unsortedItem => {
                                
                                var item = {}
                                headers.forEach(element => {
                                    item[element] = unsortedItem[element]
                                })
                                return (
                                    <tr id="record" className={(createClassName(item))} key={v4()}>
                                        {Object.keys(item).forEach(element => {
                                            if (headers.includes(element)) {
                                                var entry = '';
                                                if (element === 'Gelezen' || element === 'Akkoord') {
                                                    if (item[element] === 'Ja') {
                                                        entry = <i className="fa fa-check" aria-hidden="true" />
                                                    }
                                                    else if(item[element] === 'Nee' || element === 'Gelezen' ){
                                                        entry = <i className="fa fa-times" aria-hidden="true" />
                                                    }
                                                    else{
                                                        entry = <i className="fa fa-minus" aria-hidden="true" />
                                                    }
                                                }
                                                else if (element === 'PDF') {
                                                    entry = <div onClick={async () => await openFile(item[element])}><i className='fa fa-external-link-alt' aria-hidden='true'></i><p id="openText">Openen</p></div>
                                                }
                                                else if(element === 'Verlopen'){
                                                    if(item[element] === 'Ja'){
                                                        entry = <i style={{color: 'var(--expired)'}} className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    }
                                                    else if(item[element] === 'Binnenkort'){
                                                        entry = <i style={{color: 'var(--expiring)'}} className="fa fa-clock" aria-hidden="true"></i>
                                                    }
                                                    else{
                                                        entry = ''
                                                    }
                                                }
                                                else{
                                                    entry = item[element]
                                                }
                                                table[element] = entry
                                            }
                                        })}
                                        {Object.keys(table).map((key) => (
                                            <td onClick={key !== "PDF" ? () => handleClick(unsortedItem, title, router, notifications) : null} className={"record_" + (key in headerList ? headerList[key] : key)} key={v4()}>
                                                {table[key]}
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
            }
            else {
                content = createContent()
            }

            if (dashboardTable) {
                link = createLink()
            }
            titleh1 = createTitle()
        }
        else {
            content = createContent()
            titleh1 = createTitle()
            if (dashboardTable) {
                link = createLink()
            }
        }
        return assembleContent()
    }

    function handleClick(unsortedItem, title, router, notifications){
        var index = array.findIndex( val => val.id === unsortedItem.id )
        if(array[index].Gelezen !== undefined && array[index].Gelezen !== "Ja"){
            array[index].Gelezen = "Ja"
        }
        FetchData(unsortedItem.id ? unsortedItem.id : unsortedItem.FactuurNummer, title, router, notifications)
    }

    function createLink() {
        if(title === "Openstaande facturen"){
            if(slicedArray[0]){
                return <>
                    <p style={{fontSize: "12px", marginTop: "10px"}}>Totaal openstaand: {slicedArray[0].Totaal}</p>
                    <p id="tableLink"><span id="tableLinkClick" onClick={() => router(String(title).replace(' ','').toLowerCase())}>Meer {title.toLowerCase()} bekijken</span></p>
                </>
            }
        }
        else{
            return <p id="tableLink"><span id="tableLinkClick" onClick={() => router(String(title).replace(' ','').toLowerCase())}>Meer {title.toLowerCase()} bekijken</span></p>
        }
    }

    function createTitle() {
        if (dashboardTable) {
            switch(title){
                case "Meerwerk":
                    return <h1>Goed te keuren meerwerk {rechten ? unreadArray.length > 0 ? "("+ (unreadArray.length) +")" : '' : ''}</h1> 
                case "Rapportages":
                    return <h1>Ongelezen rapportages {rechten ? unreadArray.length > 0 ? "("+ (unreadArray.length) +")" : '': ''}</h1> 
                case "Facturen":
                    return <h1>Nieuwste facturen</h1> 
                case "Openstaande facturen":
                    return <h1>Openstaande facturen {rechten ? unreadArray.length > 0 ? "("+ (unreadArray.length) +")" : '': ''}</h1> 
                case "Tickets":
                    return <h1>Openstaande tickets</h1> 
                default:
                    break;
            }
        }
        else {
            return <h1>{title}</h1>
        }
    }

    function createContent() {
        switch(title){
            case "Meerwerk":
                return <p>Er is geen goed te keuren meerwerk.</p>
            case "Rapportages":
                return <p>Er zijn geen ongelezen rapportages.</p>
            case "Facturen":
                return <p>Er zijn geen nieuwe facturen.</p>
            case "Openstaande facturen":
                return <p>Er zijn geen openstaande facturen.</p>
            case "Tickets":
                if(dashboardTable){
                    return <p>Er zijn geen openstaande tickets.</p>
                }
                else{
                    return <><p>Er zijn geen tickets gevonden.</p><NavLink id="newTicket" to="/support">
                        Nieuw ticket
                    </NavLink></>
                }
                
            default:
                break;
        }
    }

    function assembleContent() {
        return <div id="mainSection">
            {titleh1}
            {/* <hr id="separator" /> */}
            {content}
            {link}
        </div>
    }

    function createClassName(item){
        if(item.Akkoord === "" && title === "Meerwerk"){
            if(dashboardTable){
                return "unreadRecordDashboard"
            }
            else{
                return "unreadRecord"
            }
        }
        if(item.Gelezen !== 'Ja' && title !== "Meerwerk"){
            if(title !== "Facturen" && title !== "Openstaande facturen" && title !== "Tickets"){
                if(dashboardTable){
                    return "unreadRecordDashboard"
                }
                else{
                    return "unreadRecord"
                }
            }
            else if(title === "Tickets" && item.Status !== "Gesloten"){
                if(dashboardTable){
                    return "unreadRecordDashboard"
                }
                else{
                    return "unreadRecord"
                }
            }
        }
        if(title === "Openstaande facturen"){
            if(item.Verlopen === 'Ja'){
                if(dashboardTable){
                    return "expiredRecordDashboard"
                }
                else{
                    return "expiredRecord"
                }
            }
            if(item.Verlopen === 'Binnenkort'){
                if(dashboardTable){
                    return "soonExpiredRecordDashboard"
                }
                else{
                    return "soonExpiredRecord"
                }
            }
        }
    }
}

export { 
    GenerateTable as default 
}