import '../Stylesheets/Gegevens.css';
import PasswordModal from '../Components/PasswordModal';
import ContactModalIncorrectInformation from './ContactModalIncorrectInformation';
import React from 'react';
import { v4 } from 'uuid';

class Gegevens extends React.Component {

    render() {
        const data = this.props.data

        if (data === undefined || data.ContactPersoon === undefined || data.Organisatie === undefined) {
            return  <div className="mainContent">
                        <div id="mainSection" style={{ paddingBottom: '40px' }}>
                            <h1>Mijn gegevens</h1>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo" style={{height: '30px', width: '30px', marginLeft: '12px'}}></img>
                                <p>Bezig met laden...</p>
                            </div>
                        </div>
                    </div>
        }
        else if(!data.ContactPersoon.gegevensInzien){
            return  <div className="mainContent">
                        <div id="mainSection">
                            <h1>Mijn gegevens</h1>
                            <hr id="separator" />
                            <p>U heeft geen rechten om deze gegevens te bekijken.
                            <br />Is dit niet juist? Neem contact op met onze administratie.
                            <br /><br /><a id="contactLink" href={"tel:0252794001"}><span style={{marginRight:'5px'}}><i className="fa fa-phone" aria-hidden="true"></i></span>+31(0)252-794001</a></p>
                        </div>
                    </div>
        }
        else{
            var organisationKeys = ["Naam", "Adres", "PostcodePlaats", "Email", "FactuurEmail"]
            var personalKeys = ["Aanhef", "Email"]
            var headerList = {
                "PostcodePlaats": "Postcode & woonplaats",
                "FactuurEmail": "Factuur e-mail",
                "Aanhef": "Naam",
                "Email": "E-mail"
            }

            return (
                <div className="mainContent">
                    <div id="mainSection">
                        <h1>Mijn gegevens</h1>
                        <div id="informationContainer">
                            <table id="dataTableOrganisation">
                                <thead>
                                    <tr>
                                        <th>Organisatie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {organisationKeys.map(element => {
                                        if (data.Organisatie[element]) {
                                            return <tr key={v4()}>
                                                <td><b>{element in headerList ? headerList[element] : element}</b></td>
                                                <td>{data.Organisatie[element]}</td>
                                            </tr>
                                        }
                                        else {
                                            return <tr key={v4()}>
                                                <td><b>{element in headerList ? headerList[element] : element}</b></td>
                                                <td id="empty">{element in headerList ? headerList[element] : element}</td>
                                            </tr>
                                        }
                                    })}
                                </tbody>
                            </table>
                            <hr id="separator" className='informationSeparator' />
                            <table id="dataTablePersonal">
                                <thead>
                                    <tr>
                                        <th>Persoonlijk</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {personalKeys.map(element => {
                                        if (data.ContactPersoon[element]) {
                                            return <tr key={v4()}>
                                                <td><b>{element in headerList ? headerList[element] : element}</b></td>
                                                <td>{element === "Aanhef" ? `${data.ContactPersoon[element]}. ${data.ContactPersoon["VolledigeNaam"]}` : data.ContactPersoon[element]}</td>
                                            </tr>
                                        }
                                        else {
                                            return <tr key={v4()}>
                                                <td><b>{element in headerList ? headerList[element] : element}</b></td>
                                                <td id="empty">{element in headerList ? headerList[element] : element}</td>
                                            </tr>
                                        }
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <ContactModalIncorrectInformation />
                        <PasswordModal />
                    </div>
                </div>
            );
        }
    }
}

export default Gegevens;