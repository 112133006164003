import React, { useState } from 'react';

const Notification = ({ text, type }) => {
  const [showNotification, setShowNotification] = useState(true);

  const handleClose = () => {
    setShowNotification(false);
  };

  let notificationColor;
  switch (type) {
    case 'success':
      notificationColor = 'var(--succes)';
      break;
    case 'warning':
      notificationColor = 'var(--warning)';
      break;
    case 'error':
      notificationColor = 'var(--alert)';
      break;
    default:
      notificationColor = 'var(--informatie)';
      break;
  }

  return (
    showNotification && (
      <div
        style={{
          position: 'fixed',
          backgroundColor: notificationColor,
          padding: '10px',
          borderRadius: '5px',
          color: 'white',
        }}
      >
        <span>{text}</span>
        <button
          style={{
            marginLeft: '24px',
            backgroundColor: 'transparent',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleClose}
        >
          Sluiten
        </button>
      </div>
    )
  );
};

export default Notification;
