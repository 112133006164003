import '../Stylesheets/Loading.css';

function Loading() {

    return <>
        <div id="loadingBackground" />
        <div className="loadingDiv">
            <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo"></img>
            <h1>Een ogenblik geduld alstublieft.</h1>
        </div>
    </>
}

export default Loading