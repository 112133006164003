import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import '../Stylesheets/Modal.css';

class ContactModal extends React.Component {

    state = {
        openModal: false
    }

    openModal = () => {
        this.setState({ openModal: true })
    }

    closeModal = () => {
        this.setState({ openModal: false })
    }

    render() {

        const customStyles = {
            content: {
                position: 'relative',
                inset: 'auto',
                padding: 0,
                margin: '10% auto 0',
                width: '500px'
            },
        };

        return <div id="modalContainer">
            <p onClick={this.openModal} id="openContactModal">
                <div className="iconSpacing">
                    <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                Contact
            </p>
            <Modal id="modal" open={this.state.openModal} onClose={this.closeModal} style={customStyles}>
                <div className="modal-content">
                    <span className="close" onClick={this.closeModal}>&times;</span>
                    <h1>Voor al uw vragen en opmerkingen kunt u terecht bij 4PROCES.</h1>
                    <p id="subtitle">Wij zijn iedere werkdag geopend van 09:00 tot 17:00.</p>
                    <hr id="modal_hr" />
                    <div className="modal-flex">
                        <div id="modal-information" className="contactMobile">
                            <ul>
                                <li><a id="contactLink" href={"tel:0252794001"}><i className="fa fa-phone" aria-hidden="true"></i>+31(0)252-794001</a></li>
                                <li><a id="contactLink" href={"mailto:info@4proces.nl"}><i className="fa fa-envelope" aria-hidden="true"></i>info@4proces.nl</a></li>
                                <li><a id="contactLink" href="https://goo.gl/maps/WGja36CijBJBWDzcA" target="_blank" rel="noreferrer">
                                    <div id="modal-locationcontainer">
                                        <i className="fa fa-map-marker-alt" aria-hidden="true"></i>
                                        <p id="location">Satellietbaan 41A,<br />2181MG Hillegom</p>
                                    </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div id="modal-img">
                            <img src="../images/4proces_small.png" alt="4PROCES Logo" />
                        </div>
                    </div>
                    <button onClick={this.closeModal} id="closeContactModal">
                        Sluiten
                    </button>
                </div>
            </Modal>
        </div>
    }
}

export default ContactModal;