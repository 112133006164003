import React from 'react';
import '../Stylesheets/DocumentView.css';
import '../Stylesheets/Ticket.css';
import { PostData } from '../Javascripts/API';
import DragDropImage from './DragDropImage';
import Loading from './Loading';
import Notification from './Notification';

class Ticket extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    state = {
        body: {
            description: '',
            img1: ''
        },
        loading: false
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.state.body.description.length > 0) {
            this.setState({ loading: true })
            PostData(`${process.env.REACT_APP_API_URL}/support`, this.state.body)
                .then((result) => {
                    console.log({ result })
                    if (result.code === 200) {
                        this.routingFunction('dashboard', { message: { text: result.message, type: 'success' } })
                    }
                    else if (result.message !== "Unauthorized request") {
                        this.setState({ loading: false })
                        this.setState({
                            message: {
                                text: result.message,
                                type: 'error'
                            }
                        })
                    }
                    else {
                        this.setState({ loading: false })
                        this.setState({
                            message: {
                                text: 'Uw sessie is verlopen. Log opnieuw in.',
                                type: 'error'
                            }
                        })
                    }
                })
                .catch((err) => {
                    this.setState({ loading: false })
                    console.log({ error: err })
                    this.setState({
                        message: {
                            text: 'Er ging iets fout. Probeer het later opnieuw.',
                            type: 'error'
                        }
                    })
                })
        }
    }

    handleChange(event) {
        const target = event.target.id;
        const value = event.target.value;
        const body = this.state.body;

        if (event.target?.files?.[0] || event.dataTransfer?.files?.[0]) {
            const file = event.target?.files?.[0] || event.dataTransfer?.files?.[0];

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    const fileTarget = target.split('#')[0];
                    if (file.name.endsWith('.eml')) {
                        reader.onload = () => {
                            body[fileTarget] = { file: reader.result, name: file.name };
                            this.setState({ body: body });
                        };
                        reader.readAsText(file);
                    } else {
                        // Handle image files
                        reader.onload = () => {
                            body[fileTarget] = { file: reader.result, name: file.name };
                            this.setState({ body: body });
                        };
                        reader.readAsDataURL(file);
                    }
                };

                if (file.name.endsWith('.eml')) {
                    reader.readAsText(file);
                } else {
                    reader.readAsDataURL(file);
                }
            } catch (err) {
                console.log({ error: err });
            }
        } else {
            body[target] = value;
            this.setState({ body: body });
        }
    }



    componentDidMount() {
        this.setState({
            body: {
                description: '',
                img1: '',
                img2: '',
                img3: ''
            },
            message: ''
        })
    }

    render() {
        return <div className="mainContent">
            {this.state.loading ?
                <Loading />
                :
                <div id="mainSection" style={{ paddingBottom: '40px' }}>
                    {this.state.message ? <Notification text={this.state.message.text} type={this.state.message.type} /> : null}
                    <h1>Nieuw ticket</h1>
                    <form onSubmit={(e) => { this.handleSubmit(e) }} style={{ margin: '20px 10px' }}>
                        <p style={{ padding: '10px 0' }}>Stel ons een vraag of meld een probleem.</p>
                        <label htmlFor={"description"} style={{ fontSize: '14px', marginBottom: '8px', width: '100%' }}>1. Uw vraag of melding.<span style={{ color: 'red' }}>*</span></label>
                        <div id="textareaContainer" className="description" style={{ margin: '0' }}>
                            <textarea spellCheck={false} id="description" name="description" value={this.state.body.description} onChange={this.handleChange} />
                        </div>
                        <label style={{ fontSize: '14px', margin: '16px 0 0', width: '100%' }}>2. Voeg bestanden of afbeeldingen toe ter verduidelijking (optioneel).</label>
                        <div className='images-box'>
                            <DragDropImage id="img1" image={this.state.body['img1'] || false} handleChange={(event) => this.handleChange(event)} />
                            <DragDropImage id="img2" image={this.state.body['img2'] || false} handleChange={(event) => this.handleChange(event)} />
                            <DragDropImage id="img3" image={this.state.body['img3'] || false} handleChange={(event) => this.handleChange(event)} />
                            {/* <div>
                    <label htmlFor="img1">Select an image:</label>
                    <input
                        type="file"
                        id="img1"
                        accept="image/*"
                        onChange={this.handleChange}
                    />
                    {this.state.body['img1'] && (
                        <div>
                        <h4>Preview:</h4>
                        <img src={this.state.body['img1']} alt="Preview" style={{ width: '300px' }} />
                        </div>
                    )}
                    </div> */}
                        </div>
                        <button className={this.state.body.description.length > 0 ? 'ticket-submit-button' : 'ticket-submit-button submit-disabled'} style={{ margin: '16px 0' }} type='submit'>
                            Versturen
                        </button>
                    </form>
                </div>
            }
        </div>
    }
}

export default Ticket;