import React from 'react';
import '../Stylesheets/DocumentView.css';
import DownloadModal from '../Components/DownloadModal';
import { PostData } from '../Javascripts/API';
import { v4 } from 'uuid';
import { GetData } from '../Javascripts/API';

class DocumentView extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleComment = this.handleComment.bind(this);
    }

    state = {
        akkoord: "", 
        comment : "",
        data: this.props.history.location.state.data
    }

    handleSubmit(val, id){
        PostData(`${process.env.REACT_APP_API_URL}/meerwerk/${id}/akkoord`, { "akkoord":val, "opmerking":this.state.comment })
        .then(()=>{
            var meerwerk = this.props.data.Meerwerk
            var meerwerkId = meerwerk.findIndex( val => val.id === id )
            meerwerk[meerwerkId]["Akkoord"] = val
            var notifications = this.props.notifications["MeerwerkOngelezen"]
            var updateNotif = notifications[1]
            var count = notifications[0]
            this.props.history.location.state.data.Akkoord = val;
            this.setState({akkoord:val})
            updateNotif(count-1)
        })
    }

    handleComment = event => {
        this.setState({comment:event.target.value})
    }

    async componentDidMount(){
        let data = this.props.history.location.state.data

        if(data !== undefined){
            this.setState({
                akkoord: data.Akkoord,
                comment: data.Opmerking
            })
        }
        if(data.FactuurDatum && !data.PDF && !data.TotaalOpen && this.props.data.success){
            await GetData(`${process.env.REACT_APP_API_URL}/facturen/${data.FactuurNummer}`)
            .catch(err => {
                console.log({err})
            })
            .then(response => {
                if(response?.[0]){
                    this.setState({data: {...data, ...response[0]}}) 
                }
            });
        }
    }

    render() {
        let data = this.state.data;

        if (data === undefined || (data.FactuurDatum && !data.PDF && !data.TotaalOpen)) {
            return  <div className="mainContent">
                        <div id="mainSection" style={{ paddingBottom: '40px' }}>
                            <h1>{`${data.Type} ${data.FactuurDatum ? data.FactuurDatum : ''}`}</h1>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo" style={{height: '30px', width: '30px', marginLeft: '12px'}}></img>
                                <p>Bezig met laden...</p>
                            </div>
                        </div>
                    </div>
        }
        else {
            var date
            switch(data.Type){
                case "Factuur":
                    date = data.FactuurDatum
                    break;
                case "Ticket":
                    console.log({data})
                    date = data.StartDatum
                    break;
                default:
                    date = data.Datum
            }

            var headerList = {
                'FactuurDatum':     'Datum',
                'FactuurNummer':    'Factuurnummer',
                'TotaalInclBTW':    'Bedrag',
                'TotaalBetaald':    'Betaald',
                'TotaalOpen':       'Openstaand',
                'id':               'ID',
                'AkkoordDatumTijd': 'Tijdstip akkoord',
                'StartDatum' :      'Startdatum',
                'EindDatum' :       'Einddatum'
            }
            const blackList = ['Type', 'PDF', 'id', 'Gelezen', 'Jaar', 'Id', 'Organisatie', 'Totaal', 'Herinneringen', 'Onderwerp', 'Omschrijving', 'Oplossing', 'Verlopen', 'VerlooptOp', 'Opmerking']

            const verlopen = data.Verlopen;
            const verlooptOp = data.VerlooptOp;

            const userInfo = {}
            for (const [key, value] of Object.entries(data)) {
                if (!blackList.includes(key)) {
                    if (key === 'Gelezen') {
                        if (value === '' || value === 'Nee') {
                            userInfo[key] = <i className="fa fa-times" aria-hidden="true" />
                        }
                        else {
                            userInfo[key] = 
                            <i className="fa fa-check" aria-hidden="true" />
                        }
                    }
                    else if (key === 'Akkoord') {
                        if (this.state.akkoord !== '') {
                            if (this.state.akkoord === "Ja") {
                                userInfo[key] = <i className="fa fa-check" style={{color: '#2f961a'}} aria-hidden="true" />
                            }
                            else {
                                userInfo[key] = <i className="fa fa-times" style={{color: '#b61010'}}  aria-hidden="true" />
                            }
                        }
                    }
                    else if (value !== '') {
                        userInfo[key] = value
                    }
                }
            }

            return <div className="mainContent">
                <div id="mainSection" style={{ paddingBottom: '40px' }}>
                    <h1>{data.Type + " " + date}</h1>       
                    <table id="documentInfo">
                        <tbody>
                            {Object.keys(userInfo).map((key) => (
                                <tr key={v4()}>
                                    <td style={{ fontWeight: '500', fontSize: '14px' }} key={v4()}>{key in headerList ? headerList[key] : key}</td>
                                    <td key={v4()}>{userInfo[key]}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <DownloadModal data={[data.PDF, data.Type, date]} />
                   {data.Herinneringen && data.Herinneringen.length > 0 ?
                        <table id="documentInfo">
                            <tbody>
                                {Object.keys(data.Herinneringen).map((key) => (
                                    <tr key={v4()}>
                                        <td style={{ fontWeight: '500', fontSize: '14px' }} key={v4()}>{`Herinnering ${parseInt(key)+1}`}</td>
                                        <td key={v4()}>{`Verstuurd op ${data.Herinneringen[key]}`}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table> : null
                    }
                    {verlopen === 'Ja' || verlooptOp < 0 ?
                        <div className='expired'>
                            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                            <p style={{display: 'inline'}}>De betaaltermijn van deze factuur is verstreken.</p>
                        </div>
                        : verlopen === 'Binnenkort' ?
                            <div className='expires-soon'>
                                <i className="fa fa-clock" aria-hidden="true"></i>
                                {verlooptOp < 1 ?
                                    <p style={{display: 'inline'}}><b>Let op:</b> De betaaltermijn van deze factuur verstrijkt <b>vandaag</b>.</p>
                                :   <p style={{display: 'inline'}}><b>Let op:</b> De betaaltermijn van deze factuur verstrijkt over <b>{verlooptOp} {verlooptOp > 1 ? 'dagen' : 'dag'}</b>.</p>
                                }
                            </div>
                        : verlooptOp ?
                        <div className='not-expiring'>
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                            <p style={{display: 'inline'}}>De betaaltermijn van deze factuur verstrijkt over {verlooptOp} dagen.</p>
                        </div> : null
                    }
                    {data.Type !== 'Meerwerk' ? '' : 
                        <div>
                            <h2 id="documentDescription">Omschrijving</h2>
                                {data.Omschrijving === '' ? <p style={{fontSize:'12px'}}><i>
                                Dit meerwerk bevat geen omschrijving.
                            </i></p> : <div id="textareaContainer">
                                <textarea spellCheck={false} id="beschrijving" name="beschrijving" readOnly value={data.Omschrijving}/>
                            </div>
                            }
                            <h2 id="documentDescription">Opmerking</h2>
                            {this.state.akkoord === '' ? <div>
                                <p id="commentDescription">Let op: Dit veld kan niet meer worden bewerkt zodra u klikt op "Goedkeuren" of "Afwijzen".</p>
                                <div id="textareaContainer" className="opmerking">
                                    <textarea spellCheck={false} id="beschrijving" name="beschrijving" value={this.state.comment} onChange={this.handleComment}/>
                                </div>
                                <div id="akkoordDiv">
                                <button id="goedkeuren" onClick={()=>this.handleSubmit("Ja", data.Id)}>Goedkeuren</button><button id="afwijzen" onClick={()=>this.handleSubmit("Nee", data.Id)}>Afwijzen</button>
                                </div>
                            </div> :
                                data.Opmerking === '' ? <p style={{fontSize:'12px'}}><i>
                                Bij dit meerwerk is geen opmerking toegevoegd.
                            </i></p> : 
                                <div id="textareaContainer" className="opmerking">
                                    <textarea spellCheck={false} id="beschrijving" name="beschrijving" readOnly value={data.Opmerking}/>
                                </div>
                            }
                            
                        </div>
                    }
                    {data.Type !== 'Ticket' ? '' : 
                        <div>
                            <h2 id="documentDescription">Omschrijving</h2>
                                {data.Onderwerp === '' ? <p style={{fontSize:'12px'}}><i>
                                Deze ticket bevat geen omschrijving.
                            </i></p> : <div id="textareaContainer">
                                <textarea spellCheck={false} id="beschrijving" name="beschrijving" readOnly value={data.Onderwerp}/>
                            </div>
                            }
                        </div>
                    }
                    {data.Type === 'Ticket' && data.Status === 'Gesloten' && data.Oplossing !== '' ?
                        <div>
                            <h2 id="documentDescription">Oplossing</h2>
                            <div id="textareaContainer" style={{border: '1px solid #1aa538'}}>
                                <textarea spellCheck={false} id="beschrijving" name="beschrijving" readOnly value={data.Oplossing}/>
                            </div>
                        </div> : ''
                    }
                </div>
            </div>
        }
    }
}

export default DocumentView;