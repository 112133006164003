import React from 'react';
import ReactDOM from 'react-dom';
import './Stylesheets/Global.css';
import './Stylesheets/Table.css';
import App from './Components/App';
import { BrowserRouter } from 'react-router-dom';

const mainDiv = document.createElement('div');
mainDiv.id = "app";
document.getElementById('body').appendChild(mainDiv);

ReactDOM.render(
  <div id="container">
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </div>,
  document.getElementById('app')
);