import React from 'react';
import '../Stylesheets/Login.css';
import { PostData } from '../Javascripts/API';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.setErrorMsg = this.setErrorMsg.bind(this);
    }

    state = {
        username: '',
        password: '',
        errorMsg: '',
        loginError: 'none',
        loading: false
    }

    componentDidUpdate() {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
          window.history.pushState(null, document.title, window.location.href);
        });
    }

    handleChange(event) {
        var target = event.target.id;
        var value = event.target.value;
        if (target === "loginUsername") {
            this.setState({ username: value });
        }
        else if (target === "loginPassword") {
            this.setState({ password: value });
        }
    }

    setErrorMsg(msg) {
        if(msg === 'Failed to fetch'){
            this.setState({ errorMsg: 'Uw loginpoging kon niet worden verwerkt. Probeer het later opnieuw.' });
        }
        else{
            this.setState({ errorMsg: msg });
        }

        this.setState({ loginError: 'flex' })
    }

    handleLogin(e) {
        e.preventDefault();
        var loginUsername = this.state.username;
        var loginPassword = this.state.password;
        this.setState({loading: true})

        PostData(`${process.env.REACT_APP_API_URL}/login`, { loginUsername, loginPassword })
            .then(data => {
                if (data.message !== 'Authenticated') {
                    this.setErrorMsg(data.message)
                    this.setState({loading: false})
                } else {
                    window.location.href = ('/dashboard');
                }

            })
            .catch(data => {
                this.setState({loading: false})
                this.setErrorMsg(data.message)
            })
    }

    render() {
        return (
            <>
                <div id="loginBackground" style={{backgroundImage: `url("images/background.webp")`}}></div>
                <div id="loginHelper">
                    <div id="loginContainer">
                        <div id="loginContent" style={{transition: '0.2s ease', opacity: this.state.loading ? 0 : 100}}>
                            <img src="https://webserver.4proces.nl/imgupload/uploads/4Psignature_EXT.png" alt="4PROCES Logo"></img> 
                            <h1>4PROCES WEBPORTAL</h1>
                            <hr id="loginhr" />
                            <div id="loginErrorContainer" style={{ 'display': this.state.loginError }}>
                                {/* <i className="fa fa-exclamation-triangle iconMargin" aria-hidden="true"></i> */}
                                <p id="loginErrorMessage">{this.state.errorMsg}</p>
                            </div>
                            <form onSubmit={(e) => {this.handleLogin(e)}}>
                                <label htmlFor={"loginUsername"}><i className="fa fa-user iconMargin" aria-hidden="true"></i>Gebruikersnaam</label><br />
                                <input type="text" id="loginUsername" name="loginUsername" autoComplete="off" value={this.state.username} onChange={this.handleChange} placeholder="voorbeeld@outlook.com" tabIndex={0} autoFocus={true}></input>
                                <label htmlFor={"loginPassword"}><i className="fa fa-lock iconMargin" aria-hidden="true"></i>Wachtwoord</label><br />
                                <input type="password" id="loginPassword" name="loginPassword" autoComplete="off" autoCorrect="off" autoCapitalize="off" value={this.state.password} onChange={this.handleChange} tabIndex={1}></input>
                                <button id="loginButton" type='submit' tabIndex={2}>
                                    INLOGGEN
                                </button>
                            </form>
                        </div>
                        <div id="loginLoading" style={{transition: '0.2s ease', opacity: this.state.loading ? 100 : 0, pointerEvents: this.state.loading ? 'all' : 'none'}}>
                            <img id="loadingImage" src="./images/4proces_loading.gif" alt="4PROCES Logo"></img>
                            <p>Bezig met inloggen...</p>
                        </div>
                    </div>
                    <div id="loginFooter">
                        <p><a href="https://www.4proces.nl" target="_blank" rel="noreferrer">www.4proces.nl</a></p>
                        <p><a href={"tel:0252794001"}>+31(0)252-794001</a></p>
                        <p>versie 2.3.0</p>
                    </div>
                </div>
            </>
        );
    }
}
export default Login;