import '../Stylesheets/Dashboard.css';
import '../Stylesheets/Global.css';
import '../Stylesheets/Table.css';

import { v4 } from 'uuid';
import React from 'react';
import GenerateTable from '../Javascripts/Tools';
import NewsItem from './NewsItem';
import NewsItemLoading from './NewsItemLoading';
import Notification from './Notification';

class Dashboard extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    constructor(props) {
        super(props);
        this.checkFullyLoaded = this.checkFullyLoaded.bind(this);
    }

    state = {
        message: ''
    }

    componentDidMount() {
        const historyData = this.props.history.location?.state?.data
        if(historyData?.message){
            this.setState({message: historyData.message})
            delete historyData.message
        }
    }

    checkFullyLoaded(data){
        if(
            (data.Nieuws && data.Nieuws !== 'Fetching') &&
            data.OpenstaandeFacturen !== 'Fetching' &&
            data.Facturen !== 'Fetching' &&
            data.Rapportages !== 'Fetching' &&
            data.Meerwerk !== 'Fetching' && 
            data.ContactPersoon
        ){
            return true;
        }
        else{
            return false
        }
    }

    render() {
        const data = this.props.data
        const notifications = this.props.notifications

        return (
            <div style={{width: "100%"}}>
                <div id="companyBannerWrapper">
                    <img src="https://webserver.4proces.nl/imgupload/uploads/Tulpen%204PROCES.GIF.gif" id="companyBanner" alt="4PROCES Banner"/>
                </div>
            <div className="mainContent dashboardContainer" style={{width: "auto"}}>
                
                {this.state.message ? <Notification text={this.state.message.text} type={this.state.message.type} /> : null}

                <div id='mainSection' className='news'>
                    {data.Nieuws && data.Nieuws !== 'Fetching' ? data.Nieuws.map((bericht) => {
                        if(this.checkFullyLoaded(data)){
                            return <NewsItem bericht={bericht} key={v4()}/>
                        }
                        else{
                            return <NewsItemLoading bericht={bericht} key={v4()}/>
                        }
                    }) : null}
                </div>

                {data.OpenstaandeFacturen !== 'Fetching' && data.ContactPersoon ? 
                    GenerateTable(false, data.OpenstaandeFacturen, "Openstaande facturen", ["FactuurNummer", "FactuurDatum", "TotaalInclBTW", "TotaalOpen", "Verlopen"], true, this.routingFunction, null, data.ContactPersoon.facturenInzien)
                    : GenerateTable(true, data.OpenstaandeFacturen, "Openstaande facturen")
                }
                <hr id="separator" />
                {data.Facturen !== 'Fetching' && data.ContactPersoon ? 
                    GenerateTable(false, data.Facturen, "Facturen", ["FactuurNummer", "FactuurDatum", "Omschrijving", "TotaalInclBTW"], true, this.routingFunction, null, data.ContactPersoon.facturenInzien)
                    : GenerateTable(true, data.Facturen, "Nieuwste facturen")
                }
                <hr id="separator" />
                {data.Rapportages !== 'Fetching' && data.ContactPersoon ?
                    GenerateTable(false, data.Rapportages, "Rapportages", ["Project", "Contactpersoon", "Datum"], true, this.routingFunction, notifications, data.ContactPersoon.rapportageInzien)
                    : GenerateTable(true, data.Rapportages, "Ongelezen rapportages")
                }
                <hr id="separator" />
                {data.Meerwerk !== 'Fetching' && data.ContactPersoon ?
                    GenerateTable(false, data.Meerwerk, "Meerwerk", ["Project", "Onderdeel", "Datum"], true, this.routingFunction, notifications, data.ContactPersoon.meerwerkInzien)
                    : GenerateTable(true, data.Meerwerk, "Goed te keuren meerwerk")
                }
                <hr id="separator" />
                {data.Tickets !== 'Fetching' && data.ContactPersoon ?
                    GenerateTable(false, data.Tickets, "Tickets", ["Onderwerp", "Status"], true, this.routingFunction, notifications, data.ContactPersoon.ticketsInzien)
                    : GenerateTable(true, data.Tickets, "Openstaande tickets")
                }
            </div>
        </div>

        );
        
    }
}

export default Dashboard;