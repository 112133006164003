import React from "react";
import GenerateTable from '../Javascripts/Tools';

class OpenstaandeFacturen extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    render() {
        const data = this.props.data
        if ( data.OpenstaandeFacturen === 'Fetching' || data.ContactPersoon === undefined) {
            return (
                <div className="mainContent">
                    {GenerateTable(true, data.OpenstaandeFacturen, "Openstaande facturen")}
                </div>
            )
        }
        else {
            return (
                <div className="mainContent">
                    {GenerateTable(false, data.OpenstaandeFacturen, "Openstaande facturen", ["FactuurNummer", "FactuurDatum", "TotaalInclBTW", "TotaalBetaald", "TotaalOpen", "Verlopen"], false, this.routingFunction, null, data.ContactPersoon.facturenInzien)}
                </div>
            )
        }
    }
}

export default OpenstaandeFacturen;