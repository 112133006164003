import React from 'react';
// import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import '../Stylesheets/Modal.css';
import { openFile } from '../Javascripts/OpenFile';

class DownloadModal extends React.Component {

  constructor(props) {
    super(props);
    if (!this.props.data[0] || this.props.data[0].length === 0) {
      this.state.buttonClass = 'downloadDisabled'
    }
    else {
      this.state.buttonClass = ''
    }
  }

  state = {
    openModal: false,
    buttonClass: 'downloadDisabled',
  }

  openModal = () => {
    if (this.state.buttonClass !== 'downloadDisabled') {
      this.setState({ openModal: true })
    }
  }

  closeModal = () => this.setState({ openModal: false })

  render() {
    var url = this.props.data[0]
    var type = this.props.data[1]
    // var date = this.props.data[2]

    return <div className={this.state.buttonClass} id="downloadFiles" onClick={async () => await openFile(url)}>
        <i className="fa fa-external-link-alt" aria-hidden="true"></i>
        <p>{type} openen</p>
      </div>
  }
}

export default DownloadModal;