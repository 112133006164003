import '../Stylesheets/Dashboard.css';
import '../Stylesheets/Global.css';
import React, { useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height';

function NewsItem(props) {
    const MAX_MESSAGE_HEIGHT = 60;

    const bericht = props.bericht;
    const ref = useRef(null)
    const [height, setHeight] = useState(0);
    const [extended, setExtended] = useState(false);

    useEffect(() => {
        function heightHandler(){
            setHeight(ref.current.clientHeight);
        }
        ref.current.innerHTML = bericht.Bericht;

        heightHandler()
        window.addEventListener('resize', heightHandler)

        return () => window.removeEventListener("resize", heightHandler);
    },[bericht.Bericht, ref.current?.clientHeight, extended]);


    return (
        <div id='newsSection' className={bericht.Type}>
            <div className={`news-header ${bericht.Type}-head`}>
                {
                    bericht.Type === 'informatie' ?
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                    : bericht.Type === 'waarschuwing' ?
                    <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    : bericht.Type === 'update' ?
                    <i className="fa fa-wrench" aria-hidden="true"></i>
                    : bericht.Type === 'alert' ?
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    : null
                }
                <h1>{bericht.Titel}</h1>
            </div>
            <AnimateHeight className={`news-body`} duration={400} height={(ref.current?.clientHeight <= MAX_MESSAGE_HEIGHT && extended) ? 'auto' : extended ? 'auto' : ref.current?.clientHeight <= MAX_MESSAGE_HEIGHT ? 'auto' : MAX_MESSAGE_HEIGHT + 5}>
                <p ref={ref} className='news-text'></p>

                {bericht.KnopLink && bericht.KnopLink !== "" ?
                    <a href={bericht.KnopLink} target='#blank' className={`news-button ${bericht.Type}-button`}>{bericht.Knop !== "" ? bericht.Knop : "Meer informatie"}<i style={{marginLeft: '10px'}} className="fa fa-external-link-alt" aria-hidden="true"></i></a>
                : null}
            </AnimateHeight>
            {(height > MAX_MESSAGE_HEIGHT && !extended) || (height > MAX_MESSAGE_HEIGHT && extended) ? 
                <div className={`read-more ${bericht.Type}-readmore ${extended ? 'extended' : null}`} onClick={() => setExtended(!extended)}>
                    <div className='read-more-helper' style={extended ? {height: '22px'} : null}></div>
                    <p>{extended ? "Inklappen" : "Meer lezen"}</p>
                </div>
            : null}
        </div>
    )
}

export default NewsItem