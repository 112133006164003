import React from "react";
import GenerateTable from '../Javascripts/Tools';

class Meerwerk extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    render() {
        const data = this.props.data
        const notifications = this.props.notifications

        if ( data.Meerwerk === 'Fetching' || data.ContactPersoon === undefined) {
            return (
                <div className="mainContent">
                    {GenerateTable(true, data.Meerwerk, "Meerwerk")}
                </div>
            )
        }
        else {
            return (
                <div className="mainContent">
                    {GenerateTable(false, data.Meerwerk, "Meerwerk", ["Project", "Onderdeel", "Datum", "Uren", "Gelezen", "Akkoord"], false, this.routingFunction, notifications, data.ContactPersoon.meerwerkInzien)}
                </div>
            )
        }
    }
}

export default Meerwerk;