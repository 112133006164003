import React from "react";
import GenerateTable from '../Javascripts/Tools';

class Rapportages extends React.Component {
    routingFunction = (path, data = null) => {
        this.props.history.push({
            pathname: '/' + path,
            state: { data: data }
        });
    }

    render() {
        const data = this.props.data
        const notifications = this.props.notifications

        if ( data.Rapportages === 'Fetching' || data.ContactPersoon === undefined) {
            return (
                <div className="mainContent">
                    {GenerateTable(true, data.Rapportages, "Rapportages")}
                </div>
            )
        }
        else {
            return (
                <div className="mainContent">
                    {GenerateTable(false, data.Rapportages, "Rapportages", ["Project", "Datum", "Contactpersoon", "Gelezen"], false, this.routingFunction, notifications, data.ContactPersoon.rapportageInzien)}
                </div>
            )
        }
    }
}

export default Rapportages;