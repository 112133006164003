import React from 'react';
import '../Stylesheets/NotFound.css';

class NotFound extends React.Component {

    render() {
        return <div id="notFoundBackground">
            <div className="notFoundDiv">
                <img id="notFoundImage" src="/images/4proces_small.png" alt="4PROCES Logo"></img>
                <h1>De opgevraagde pagina is niet gevonden.</h1>
                <button id="notFoundButton" onClick={this.props.history.goBack}>Terug</button>
            </div>
        </div>
    }
}

export default NotFound