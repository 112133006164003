import { React, useState, useEffect } from 'react';
import Navbar from './Navbar';
import Routes from './Routes';
import { useLocation } from "react-router-dom";
import { GetData } from '../Javascripts/API';

function App(props) {
    const [showNavbar, setShowNavbar] = useState(false)
    const currentLocation = useLocation().pathname
    useEffect(function () {
        if (currentLocation !== '/') {
            setShowNavbar(true)
        } else {
            setShowNavbar(false)
        }
        fetchInfo();
        // eslint-disable-next-line
    }, [currentLocation], fetchInfo)

    const [data, setData] = useState({})
    const [NavbarData, setNavBarData] = useState({})
    const [RapportagesOngelezen, setRapportagesOngelezen] = useState()
    const [MeerwerkOngelezen, setMeerwerkOngelezen] = useState()

    async function fetchInfo(){
        if (Object.keys(data).length === 0 && currentLocation !== '/') {
            let dataObj = {
                Nieuws: 'Fetching',
                Facturen: 'Fetching',
                OpenstaandeFacturen: 'Fetching',
                Meerwerk: 'Fetching',
                Rapportages: 'Fetching',
                Tickets: 'Fetching'
            }
            GetData(`${process.env.REACT_APP_API_URL}/userdata`)
            .then(async response => {
                if(response !== "Unauthorized request"){
                    dataObj = {...dataObj,...response}
                    var x = {}
                    x["ContactPersoon"] = response["ContactPersoon"];
                    x["Organisatie"] = response["Organisatie"];
                    setNavBarData(x)
                    setData(dataObj)

                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardnieuws`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response}
                            setData(dataObj)
                        }
                    })
                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardopenstaandefacturen`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response}
                            setData(dataObj)
                        }
                    })
                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardfacturen`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response}
                            setData(dataObj)
                        }
                    })
                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardrapportages`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response}
                            setData(dataObj)
                            setRapportagesOngelezen(response["Navbar"].RapportagesOngelezen)
                        }
                    })
                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardmeerwerk`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response,...{success: true}}
                            setData(dataObj)
                            setMeerwerkOngelezen(response["Navbar"].MeerwerkOngelezen)
                        }
                    })
                    await GetData(`${process.env.REACT_APP_API_URL}/dashboardtickets`)
                    .then(response => {
                        if(response !== "Unauthorized request"){
                            dataObj = {...dataObj,...response,...{success: true}}
                            setData(dataObj)
                        }
                    })
                    setData(dataObj)
                }
            })
        }
    }
    return (
        <div id="innerContainer">
            {showNavbar ? <Navbar data={NavbarData} notifications={{RapportagesOngelezen:RapportagesOngelezen,MeerwerkOngelezen:MeerwerkOngelezen,OpenstaandeFacturen:data.OpenstaandeFacturen && data.OpenstaandeFacturen !== 'Fetching' && data.OpenstaandeFacturen !== 401 ? data.OpenstaandeFacturen.length : 0}}/> : null}
            <Routes data={data} history={props.history } notifications={{RapportagesOngelezen:[RapportagesOngelezen, setRapportagesOngelezen],MeerwerkOngelezen:[MeerwerkOngelezen, setMeerwerkOngelezen], OpenstaandeFacturen:data.OpenstaandeFacturen && data.OpenstaandeFacturen !== 'Fetching' ? data.OpenstaandeFacturen.length : 0}}/>
        </div>
    )
}

export default App